import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Drawer,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  useMediaQuery,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const StyledButton = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#7366FF",
  color: "white",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#5A52D6",
  },
});

const Teacher = () => {
  const [rows, setRows] = useState([
    {
      id: 1,
      name: "John Doe",
      aliasName: "Super Admin",
      email: "john@example.com",
      number: "1234567890",
      password: "password123",
      subject: "Mathematics",
      experience: "5 years",
      address: "123, Main Street, City",
      createdAt: "2023-06-26",
      roles: ["Teacher"],
    },
    {
      id: 2,
      name: "Yogi",
      aliasName: "Manager",
      email: "yogi@example.com",
      number: "9876543210",
      password: "password456",
      subject: "Science",
      experience: "3 years",
      address: "456, Park Avenue, City",
      createdAt: "2023-06-26",
      roles: ["Teacher"],
    },
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const isMobile = useMediaQuery("(max-width:600px)");

  const filteredRows = rows.filter(
    (row) =>
      row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditRow = (row) => {
    setSelectedRow(row);
    setIsDrawerOpen(true);
  };

  const handleSaveChanges = () => {
    if (selectedRow.id) {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === selectedRow.id ? selectedRow : row))
      );
    } else {
      const newId = rows.length + 1;
      setRows([
        ...rows,
        {
          ...selectedRow,
          id: newId,
          createdAt: new Date().toISOString().split("T")[0],
        },
      ]);
    }
    setSelectedRow(null);
    setIsDrawerOpen(false);
  };

  const handleInputChange = (field, value) => {
    setSelectedRow((prevRow) => ({
      ...prevRow,
      [field]: value,
    }));
  };

  const columns = [
    { field: "id", headerName: "SL", flex: 0.3 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "aliasName", headerName: "Alias Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "number", headerName: "Number", flex: 1 },
    { field: "subject", headerName: "Subject", flex: 1 },
    { field: "experience", headerName: "Experience", flex: 1 },
    { field: "address", headerName: "Address", flex: 1 },
    {
      field: "roles",
      headerName: "Roles",
      flex: 1,
      renderCell: (params) =>
        params.value.map((role, idx) => (
          <Chip
            key={idx}
            label={role}
            sx={{
              marginRight: "5px",
              backgroundColor: "#7366FF",
              color: "white",
              fontWeight: "bold",
              height: "22px",
            }}
          />
        )),
    },
  ];

  return (
    <div
      style={{
        fontFamily: "Poppins",
        padding: isMobile ? "10px" : "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#2b3674",
          }}
        >
          Teachers
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: "10px",
          }}
        >
          <TextField
            placeholder="Search teacher name..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: isMobile ? "100%" : 300,
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                backgroundColor: "#f5f5f5",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MagnifyingGlassIcon
                    style={{ width: "20px", height: "20px", color: "#7366FF" }}
                  />
                </InputAdornment>
              ),
            }}
          />
           <StyledButton
            onClick={() => {
              setSelectedRow({
                name: "",
                aliasName: "",
                email: "",
                number: "",
                password: "",
                subject: "",
                experience: "",
                address: "",
                roles: [],
              });
              setIsDrawerOpen(true);
            }}
            sx={{ width: isMobile ? "100%" : "auto" }}
          >
            Create Teacher
          </StyledButton>
        </Box>
      </Box>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={filteredRows}
          columns={
            isMobile ? columns.filter((col) => col.field !== "number") : columns
          }
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <div
          style={{
            width: isMobile ? "100vw" : 400,
            padding: "20px",
            fontFamily: "Poppins",
          }}
        >
          <h3>{selectedRow?.id ? "Edit Teacher" : "Add New Teacher"}</h3>
          <TextField
            label="Name"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.name || ""}
            onChange={(e) => handleInputChange("name", e.target.value)}
          />
          <TextField
            label="Alias Name"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.aliasName || ""}
            onChange={(e) => handleInputChange("aliasName", e.target.value)}
          />
          <TextField
            label="Number"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.number || ""}
            onChange={(e) => handleInputChange("number", e.target.value)}
          />
          <TextField
            label="Email"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.email || ""}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            size="small"
            type="password"
            fullWidth
            margin="normal"
            value={selectedRow?.password || ""}
            onChange={(e) => handleInputChange("password", e.target.value)}
          />
          <TextField
            label="Subject"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.subject || ""}
            onChange={(e) => handleInputChange("subject", e.target.value)}
          />
          <TextField
            label="Experience"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.experience || ""}
            onChange={(e) => handleInputChange("experience", e.target.value)}
          />
          <TextField
            label="Address"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.address || ""}
            onChange={(e) => handleInputChange("address", e.target.value)}
          />
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel>Roles</InputLabel>
            <Select
              multiple
              value={selectedRow?.roles || []}
              onChange={(e) => handleInputChange("roles", e.target.value)}
              renderValue={(selected) => (
                <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      style={{
                        backgroundColor: "#7366FF",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    />
                  ))}
                </div>
              )}
            >
              {/* <MenuItem value="Admin">Admin</MenuItem> */}
              <MenuItem value="Teacher">Teacher</MenuItem>
            </Select>
          </FormControl>
          <StyledButton
            fullWidth
            onClick={handleSaveChanges}
            sx={{ marginTop: "20px" }}
          >
            Save Changes
          </StyledButton>
        </div>
      </Drawer>
    </div>
  );
};

export default Teacher;
