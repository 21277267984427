import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Chip,
  Button,
  List,
  ListItem,
  ListItemText,
  Link as MuiLink,
  Modal,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: "12px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  marginBottom: theme.spacing(3),
  fontFamily: "'Poppins', sans-serif",
}));


const BatchDetails = () => {
  const { id } = useParams(); // Get batch ID from URL
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // Example Batch Data
  const batchInfo = {
    batchName: "Batch A - Algebra",
    class: "12th",
    startDate: "2023-12-01",
    endDate: "2024-03-01",
    targetYear: "2024",
    bannerImage: "https://via.placeholder.com/150", // Placeholder image URL
    teachers: [
      { name: "John Doe", subject: "Mathematics" },
      { name: "Jane Smith", subject: "Physics" },
      { name: "Emily Davis", subject: "Chemistry" },
    ],
    category: "Mathematics",
    fee: "Rs1500",
    language: "English",
    description:
      "This batch focuses on advanced algebra topics for Class 12 students, preparing them for competitive exams and school finals.",
    status: "Active",
  };
  const [testDate, setTestDate] = useState("");
  const [resultDate, setResultDate] = useState("");
  const handleSave = () => {
    console.log("Test Date:", testDate);
    console.log("Result Date:", resultDate);
    setOpen(false);
  };
  const [selectedDate, setSelectedDate] = useState(new Date()); // State to hold selected date
  const [value, onChange] = useState(new Date());

  return (
    <Box sx={{ padding: 4, fontFamily: "'Poppins', sans-serif" }}>
      {/* Back Button */}
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => window.history.back()}
        sx={{
          marginBottom: 3,
          textTransform: "none",
          fontWeight: "bold",
          color: "#2563eb",
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Back to Batch List
      </Button>

      {/* Header */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          marginBottom: 3,
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Batch Details
      </Typography>
      <div style={{marginBottom:"20px"}}><Calendar onChange={onChange} value={value}  /></div>
      {/* Batch Info Section */}
      <StyledPaper>
        <Typography
          variant="body2"
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          Basic Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography mt={2}>
              <strong>Batch Name:</strong> {batchInfo.batchName}
            </Typography>
            <Typography mt={2}>
              <strong>Class:</strong> {batchInfo.class}
            </Typography>
            <Typography mt={2}>
              <strong>Batch Start Date:</strong> {batchInfo.startDate}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography mt={2}>
              <strong>Batch End Date:</strong> {batchInfo.endDate}
            </Typography>
            <Typography mt={2}>
              <strong>Target Year:</strong> {batchInfo.targetYear}
            </Typography>
            <Typography mt={2}>
            <Typography mt={2}>
              <strong>Language:</strong> {batchInfo.language}
            </Typography>
            <Typography mt={2}>
              <strong>About The Batch:</strong> {batchInfo.description}
            </Typography>
             
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography mt={2}>
              <strong>Category:</strong> {batchInfo.category}
            </Typography>
            <Typography mt={2}>
              <strong>Batch Fee:</strong> {batchInfo.fee}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <strong>Banner Image:</strong>
              <br />
              <img
                src={batchInfo.bannerImage}
                alt="Batch Banner"
                style={{
                  marginTop: "8px",
                  width: "150px",
                  height: "80px",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                }}
              />
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 2 }}>
          <Chip
            label={batchInfo.status}
            color={batchInfo.status === "Active" ? "success" : "default"}
            sx={{
              fontWeight: "bold",
              padding: "5px 10px",
              fontFamily: "'Poppins', sans-serif",
            }}
          />
        </Box>
      </StyledPaper>
        {/* Teachers Section */}
        <StyledPaper>
        <Typography
          variant="body2"
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
            fontFamily: "'Poppins', sans-serif",
          }}
        >
        Tests
        </Typography>
        <List>
           
            <div style={{display:"flex",justifyContent:"space-between"}}>
            <ListItem >
                <MuiLink
                
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                >
                test1
                </MuiLink>

                <Button
                  onClick={handleOpen}
                  style={{ marginLeft: "20px" }}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Schedule Test
                </Button>
              
              </ListItem>
            
            </div>
              <TableContainer component={Paper} className="mt-5"  style={{marginTop:"25px"}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Test Name</strong></TableCell>
              <TableCell><strong>Schedule Date</strong></TableCell>
              <TableCell><strong>Result Date</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
              <TableRow >
                <TableCell>Test1</TableCell>
                <TableCell>2024-12-02</TableCell>
                <TableCell>2024-12-03</TableCell>
              </TableRow>
           
          </TableBody>
        </Table>
      </TableContainer>
          </List>
     
      </StyledPaper>

      {/* Teachers Section */}
      <StyledPaper>
        <Typography
          variant="body2"
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          Teachers and Subjects
        </Typography>
        <List>
          {batchInfo.teachers.map((teacher, index) => (
            <ListItem key={index} disablePadding>
              <ListItemText
                primary={
                  <Typography>
                    <strong>Teacher:</strong> {teacher.name}
                  </Typography>
                }
                secondary={
                  <Typography color="textSecondary">
                    <strong>Subject:</strong> {teacher.subject}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
     
      </StyledPaper>


{/* eqfweji */}




      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" gutterBottom>
            Schedule Test and Result
          </Typography>

          {/* Test Schedule Input */}
          <Box mt={2}>
            <TextField
              fullWidth
              label="Test Schedule"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={testDate}
              onChange={(e) => setTestDate(e.target.value)}
            />
          </Box>

          {/* Result Schedule Input */}
          <Box mt={2}>
            <TextField
              fullWidth
              label="Result Schedule"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={resultDate}
              onChange={(e) => setResultDate(e.target.value)}
            />
          </Box>

          {/* Action Buttons */}
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Button onClick={handleClose} color="secondary" sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default BatchDetails;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};