import React, { useState } from "react";
import { Box, useMediaQuery, IconButton, Drawer } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import SidebarMenu from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";
import Dashboard from "./pages/Dashboard/Dashboard";
import Admin from "./pages/Admin/Admin";
import AdminDetails from "./pages/Admin/AdminDetails";

import NotFound from "./pages/NotFound/NotFound";
import SignIn from "./pages/Auth/SignIn";
import SignUp from "./pages/Auth/SignUp";
import Tickets from "./pages/Ticket/Tickets";

import ResolvedTicket from "./pages/Ticket/ResolvedTicket";

import Payments from "./pages/Payments/Payments";
import Analytics from "./pages/Analytics/Analytics";

import Settings from "./pages/Setting/Settings";
import Marketing from "./pages/Marketing/Marketing";
import Coupons from "./pages/Coupons/Coupons";
import Notifications from "./pages/Notifications/Notifications";

import MenuIcon from "@mui/icons-material/Menu";
import Teacher from "./pages/Teacher/Teacher";
import Student from "./pages/Student/Student";
import Template from "./pages/TestManagement/Template";
import StudentDetails from "./pages/Student/StudentDetails";
import TCreations from "./pages/TestManagement/TCreations";
import OnlineOffline from "./pages/TestManagement/OnlineOffline";
import TseriesCreation from "./pages/TestManagement/TseriesCreation";
import Wallet from "./pages/Wallet/Wallet";
import AppProfile from "./pages/AppSettings/AppProfile";
import TermsConditions from "./pages/AppSettings/TermsConditions";
import FAQ from "./pages/AppSettings/FAQ";
import PrivacyPolicy from "./pages/AppSettings/PrivacyPolicy";
import Testimonials from "./pages/AppSettings/Testimonials";
import CancellationPolicy from "./pages/AppSettings/CancellationPolicy";
import Newsletter from "./pages/AppSettings/Newsletter";
import TCreationlist from "./pages/TestManagement/TCreationlist";
import QuestionManagment from "./pages/QUestionManagement/QuestionManagment";
import QuestionCreat from "./pages/QUestionManagement/QuestionCreat";
import QuestionDetails from "./pages/QUestionManagement/QuestionDetails";
import Batches from "./pages/BatchCreation/Batches";
import BatchContent from "./pages/BatchCreation/BatchContent";
import CreateBatch from "./pages/BatchCreation/CreateBatch";
import BatchDetails from "./pages/BatchCreation/BatchDetails";
import Content from "./pages/BatchCreation/Content";
import ContentDetails from "./pages/BatchCreation/ContentDetails";
import DoubtManagement from "./pages/DoubtManagement";
import OMRUpload from "./pages/UploadOMR/OMRUpload";

const App = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  // Custom Layout Component
  const MainLayout = ({ children }) => {
    const location = useLocation();
    const hideSidebarRoutes = ["/", "/signup"]; // Routes without sidebar
    const isSidebarVisible = !hideSidebarRoutes.includes(
      location.pathname.toLowerCase()
    );

    return (
      <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        {/* Sidebar for Desktop */}
        {!isMobile && isSidebarVisible && <SidebarMenu />}

        {/* Sidebar as Drawer for Mobile */}
        {isMobile && isSidebarVisible && (
          <Drawer
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            sx={{
              "& .MuiDrawer-paper": {
                width: "240px",
                backgroundColor: "#ffffff",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
              },
            }}
          >
            <SidebarMenu />
          </Drawer>
        )}

        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {/* Header */}
          {isSidebarVisible && (
            <Header>
              {isMobile && (
                <IconButton
                  onClick={() => setIsDrawerOpen(true)}
                  sx={{ color: "#2563eb", position: "absolute", left: "16px" }}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Header>
          )}

          {/* Main Content */}
          <Box
            sx={{
              flex: 1,
              overflow: "auto",
              padding: isMobile ? "8px" : "16px",
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Router>
      <MainLayout>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          {/* Private Routes */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/adminDetails" element={<AdminDetails />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/resolvedTicket" element={<ResolvedTicket />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/teacher" element={<Teacher />} />
          <Route path="/students" element={<Student />} />
          <Route path="/studentDetails/:id" element={<StudentDetails />} />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/template" element={<Template />} />
          <Route path="/TCreations" element={<TCreations />} />
          <Route path="/TCreationlist" element={<TCreationlist />} />
          <Route path="/OnlineOffline" element={<OnlineOffline />} />
          <Route path="/TSeries" element={<TseriesCreation />} />
          <Route path="/marketing" element={<Marketing />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/AppProfile" element={<AppProfile />} />
          <Route path="/termscondition" element={<TermsConditions />} />
          <Route path="/FAQ" element={<FAQ />} />   
          <Route path="/Policy" element={<PrivacyPolicy />} />
          <Route path="/CancellationPolicy" element={<CancellationPolicy />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/blogs&newsletter" element={<Newsletter />} />
          blogs&newsletter
          <Route path="/settings" element={<Settings />} />
          <Route path="/Notifications" element={<Notifications />} />
          <Route path="/question-managment" element={<QuestionManagment />} />
          <Route path="/question-create" element={<QuestionCreat />} />
          <Route path="/question/:id" element={<QuestionDetails />} />
          <Route path="/batches" element={<Batches />} />
          <Route path="/batches-content" element={<Content />} />
          <Route path="/batches-content/:id" element={<ContentDetails />} />
          <Route path="/create-batch" element={<CreateBatch />} />
          <Route path="/batch-details/:id" element={<BatchDetails />} />
          <Route path="/doubt" element={<DoubtManagement />} />
          <Route path="/upload-omr" element={<OMRUpload />} />
          {/* <Route path="/batches-conten" element={<Content />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </MainLayout>
    </Router>
  );
};

export default App;
